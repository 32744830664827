import {configure} from 'vee-validate'
import {localize} from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import pl from '@vee-validate/i18n/dist/locale/pl.json'
import lt from '@vee-validate/i18n/dist/locale/lt.json'

configure({
    generateMessage: localize({
        en,
        lt,
        pl
    }),
})

configure({
        generateMessage: localize('en', {
            messages: {
                required: 'Field is required',
                min: 'The field must be at least 0:{min} characters'
            },
        }),

    },
    {
        generateMessage: localize('pl', {
            messages: {
                required: 'Pole jest wymagane',
                min: ' Pole nazwy musi mieć co najmniej 0:{min} znaki'
            },
        }),
    },
    {
        generateMessage: localize('lt', {
            messages: {
                required: 'Lauką būtina užpildyti',
                min: 'Lauką turi sudaryti bent 0:{min} simbolių'
            },
        }),
    }
);




