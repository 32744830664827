{
  "global": {
    "mainTitle": "Timber bees | hurt",
    "offline": "Brak połączenia z internetem.",
    "tokenExpired": "Twoja sesja logowania wygasła. Zaloguj się ponownie.",
    "back": "Wstecz",
    "home": "Główna",
    "empty list": "List jest pusty"
  },
  "login": {
    "email": "Adres e-mail",
    "password": "Hasło",
    "remember": "Zapamiętać?",
    "signin": "Zaloguj się",
    "forgotpass": "Zapomniałeś hasła?",
    "emailError": "Podaj adres e-mail.",
    "passError": "Hasło wymagane.",
    "emailError2": "Niepoprawny adres e-mail",
    "passError2": "lub niepoprawne hasło",
    "remindMessage": "Wpisz adres e-mail. Wyślemy Ci instrukcje, jak zmienić hasło.",
    "send": "Wyślij",
    "backToLogin": "Powrót do logowania",
    "enterEmailCode": "Wpisz przesłany e-mailem kod aktywacyjny i nowe hasło.",
    "passcode": "Kod aktywacyjny",
    "newPass": "Nowe hasło",
    "buttonChange": "Zmień",
    "codeSent": "Sprawdź swój e-mail",
    "enterNewPass": "Wpisz nowe hasło",
    "newPassError": "Musi mieć co najmniej 8 znaków",
    "codeError": "Niepoprawny lub nieważny kod",
    "resetSuccess": "Świetnie, utworzono nowe hasło. Możesz teraz zalogować się!",
    "wrongCode": "Niepoprawny lub nieważny kod",
    "newPassSuccess": "Świetnie, utworzono nowe hasło. Możesz teraz zalogować się!"
  },
  "register": {
    "code": "Numer identyfikacyjny firmy",
    "vatCode": "Numer VAT",
    "representative": "Przedstawiciel firmy",
    "name": "Imię",
    "surname": "Nazwisko",
    "email": "E-mail",
    "phone": "Numer tel.",
    "newsletter": "Chcesz zapisać się do newslettera?",
    "buttonRegister": "Zarejestruj się",
    "codeError": "Podaj numer identyfikacyjny firmy",
    "nameError": "Jak masz na imię?",
    "surnameError": "Twoje nazwisko",
    "emailError": "Adres e-mail jest wymagany",
    "phoneError": "Wpisz kontaktowy numer tel.",
    "errorsError": "Popraw błędy.",
    "codeErrorWrong": "Niepoprawny numer identyfikacyjny firmy",
    "vatCodeError": "Niepoprawny numer VAT",
    "nameErrorWrong": "Niepoprawne imię",
    "surnameErrorWrong": "Niepoprawne nazwisko",
    "emailErrorWrong": "Niepoprawny adres e-mail",
    "emailErrorExists": "Taki e-mail jest już zarejestrowany",
    "phoneErrorWrong": "Niepoprawny numer tel.",
    "registrationSuccess": "Twoja rejestracja została zaakceptowana. Administrator sprawdzi dane i poinformuje Cię na podany e-mail."
  },
  "account": {
    "account": "Konto",
    "details": "Dane konta",
    "name": "Imię",
    "surname": "Nazwisko",
    "email": "E-mail",
    "phone": "Nr tel. ",
    "ordersstat": "Utworzono / Zamówienia firmy",
    "debt_limit": "Twoja firma ma dług wyższy o niż udzielony kredyt. Spłaćzadłużenie.",
    "errorName": "Wymagane, 3 i więcej znaków",
    "errorSurname": " Wymagane, 3 i więcej znaków",
    "errorEmail": "Nie można zmienić",
    "errorPhone": "Wymagane, 9 i więcej znaków",
    "accountUpdated": "Konto zaktualizowane"
  },
  "company": {
    "details": "Dane firmy",
    "title": "Nazwa",
    "code": "Numer identyfikacyjny",
    "vatcode": "Numer VAT",
    "street": "Ulica, nr",
    "postcode": "Kod pocztowy",
    "city": "Miasto",
    "country": "Kraj",
    "email": "E-mail",
    "phone": "Numer tel.",
    "www": "www",
    "limit": "Limit",
    "debt": "Dług"
  },
  "orders": {
    "generate": "Twój plik jest tworzony, proszę czekać",
    "title": "Zamówienia",
    "noOrders": "Brak zamówień",
    "noOrdersMessage": "Nie dokonałeś jeszcze co najmniej jednego zakupu. Szczegóły Twojego zamówienia zostaną podane tutaj.",
    "continueShopping": "Kontynuuj zakup",
    "order": "Zamówienie",
    "complete": "Wykonane",
    "status": {
      "0": "Przeglądane",
      "1": "Zarejestrowane",
      "2": "Sprawdzane",
      "3": "Status płatności",
      "4": "Rezerwacja towarów",
      "5": "Kompletowanie towarów",
      "6": "Wezwanie przewoźnika",
      "7": "Wysłanie towarów",
      "8": "Dostawa towarów",
      "9": "W trakcie wykonania",
      "10": "W trakcie wykonania",
      "11": "W trakcie wykonania",
      "12": "Przygotowane do odbioru",
      "13": "Wykonano",
      "19": "Wywieziono do działu",
      "90": "Anulowano"
    },
    "measure": {
      "1": "szt.",
      "2": "",
      "3": "",
      "5": "m",
      "6": "m³"
    },
    "details": "Szczegóły zamówienia",
    "products": "Towary",
    "product": "Towar",
    "quantity": "Ilość",
    "price": "Cena",
    "price_m3": "Cena m³",
    "discount": "Rabat",
    "volume": "m³",
    "total": "Kwota",
    "issued": "Data zamówienia",
    "manager": "Menadżer",
    "shipping_address": "Adres dostawy",
    "assistance": "Pomoc",
    "print_order": "Drukuj",
    "payment_information": "Zestawienie zamówienia",
    "weight": "Waga, kg",
    "order_subtotal": "Kwota częściowa zamówienia",
    "order_shipping": "Dostawa zamówienia",
    "order_tax": "VAT zamówienia",
    "taxes": "Podatki",
    "order_total": "Kwota zamówienia",
    "action": "Czynność"
  },
  "products": {
    "groups": "Grupy towarów",
    "more": "Więcej",
    "products": "Produkty",
    "subgroups": "Podgrupy",
    "packs": "Opak.",
    "length": "Długość",
    "error_parcel_reserved": "Ten pakiet został już zarezerwowany przez innego kupującego.",
    "filter": "Filtruj",
    "category": "Kategorie produktów"
  },
  "cart": {
    "title": "Koszyk",
    "empty": "Koszyk jest pusty",
    "empty_summary": "Twój koszyk jest pusty. Dodaj produkty do koszyka i złóż zamówienie.",
    "num_items": "Towar(y) w koszyku",
    "under_manager": "Policzymy",
    "checkout": "Zarezerwuj",
    "notes_title": "Uwagi dotyczące zamówienia",
    "notes_summary": "Wpisz uwagi (adres, czas dostawy itp.) lub inne ważne informacje dotyczące tego zamówienia (opcjonalnie)",
    "no_products": "Nie można złożyć zamówienia, gdy w koszyku nie ma towarów.",
    "time_left": "Rezerwacja zakończy się za",
    "susses_order_created": "zostało utworzone",
    "your_order": "Twoje zamówienie"
  },
  "pages": {
    "account": "Konto",
    "account_edit": "Edytuj konto"
  },
  "validator": {
    "required_name": "Jak masz na imię?"
  }
}
