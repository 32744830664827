<script setup>
import {computed, inject} from "vue";
import Logout from "./logout.vue"
import logo from '@/assets/img/logo/medzio-bites-logo-new.svg'
import {useUserStore} from "@/stores/user"
import {useI18n} from "vue-i18n";

const emitter = inject('emitter')
const {locale} = useI18n()
const userStore = useUserStore()
const authUser = computed(() => userStore.authUser)
</script>

<template>
  <div class="main-sidebar">
    <router-link :to="{name:'home'}" class="my-3 d-flex justify-content-center">
      <img :src="logo" alt="logo" />
    </router-link>
    <div class="sidebar-inner">
      <ul class="icon-menu">
        <li>
          <router-link :to="{name:'home'}" class="sidebar_item">
            <i class="bi bi-house-fill"></i>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'cart'}"
                       class="sidebar_item d-flex is-flex-direction-column align-items-center is-relative navbar-button-icon-badge">
            <span class="btn-icon"><i class="bi bi-cart-fill"></i></span>
            <span class="button-extra-txt"
                  v-if="authUser.cartAlert && authUser.cartVolume">{{ authUser.cartVolume }}</span>
            <span class="nav-item-badge" v-if="authUser.cartAlert"></span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'orders'}" class="sidebar_item">
            <span class="btn-icon"><i class="bi bi-wallet-fill"></i></span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'account'}" v-if="authUser"
                       class="sidebar_item d-flex is-flex-direction-column align-items-center is-relative navbar-button-icon-badge">
            <span class="btn-icon">  <i class="bi bi-person-square"></i></span>
            <span class="nav-item-badge" v-if="authUser?.accountAlert !== ''"></span>
          </router-link>
        </li>
<!--        <li class="switchLangMobile">-->
<!--          <a href="#" @click="emitter.emit('showLangList')"-->
<!--             class="text-uppercase sidebar_item"-->
<!--             style="color: #999">{{ locale }} </a>-->
<!--        </li>-->
        <li >
          <logout/>
        </li>
      </ul>
    </div>
  </div>
</template>
